import React from 'react';
import './index.css';
import Button from '../Button';

function OneStepCloserSection(props) {
    return (
        <section className="one-step-closer-section">
            <div className="elementor-container">
                <div className="elementor-widget-wrap">
                    <div className="title-wrapper-5 elementor-element elementor-widget">
                        <h1 className="title elementor-heading-title">TAKE ONE STEP CLOSER TO A HEALTHIER, HAPPIER WELL WORLD FOR YOU!</h1>
                    </div>
                    <section className="content-section">
                        <div className="image-container-3">
                            <div className="image-wrapper-3">
                                <div className="elementor-widget-wrap">
                                    <img 
                                        decoding="async" 
                                        src="https://wellworld.io/wp-content/uploads/2022/10/woman.fruit_.veggies.smoothie.jpg" 
                                        className="rectangular-image" 
                                        alt="" 
                                        loading="lazy" 
                                        width="2025" 
                                        height="1079" />
                                </div>
                            </div>
                        </div>
                        <div className="text-content">
                            <div className="elementor-widget-wrap text-container-1">
                                <div className="elementor-widget">
                                    <div className="title-wrapper-6">
                                        <h5 className="elementor-heading-title title">Start your own personalized journey of health & wellness with Well World and Designs for Health. </h5>
                                    </div>
                                </div>
                                <div>
                                    <div className="text-wrapper-3">
                                        <p>Take our simple health and wellness survey and get your personalized recommendation today!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="button-container-2">
                        <Button href={props.href} title="TAKE THE HEALTH ASSESSMENT" />
                    </div>
                </div>
            </div>
        </section>
    )
}


export default OneStepCloserSection;