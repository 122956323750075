import React from 'react';
import './index.css';
import Button from '../Button';

function Header(props) {
    return (
        <>
            <section>
                <div className="button-container">
                    <Button href={props.href} title="TAKE THE HEALTH ASSESSMENT" />
                </div>
            </section>
            <section>
                <div className="discount-container">
                    <h6> 20% OFF YOUR FIRST ORDER </h6>
                </div>
            </section>
        </>
    )
}


export default Header;