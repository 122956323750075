import React from 'react';
import { FaEnvelope } from 'react-icons/fa';
import './index.css';

function Footer() {
    return (
        <section className="footer-section">
            <div className="footer-container">
                <div className="footer-img-container">
                    <img className="footer-img" src="https://wellworld.io/wp-content/uploads/2019/12/ww.hor_.WHT@2x-400x100.png" alt="img" />
                </div>
                <section className="footer-tos-section">
                    <div className="footer-tos-container">
                        <div className="trademark-container">
                            <span className="trademark">
                                ©  2022 Designs for Health, Inc.
                            </span>
                        </div>
                        <div className="tos-container">
                            <div className="tos">
                                <span>
                                    <a href="https://www.designsforhealth.com/terms-of-use">Terms of Use</a> 
                                </span>
                                <span className="separator">
                                    |
                                </span>
                                <span>
                                    <a href="https://www.designsforhealth.com/privacy-notice">Privacy Notice</a>
                                </span>
                            </div>
                        </div>
                        <div className="email-container">
                            <div className="email">
                                <a href="mailto:support@designsforhealth.com">
                                    <span className="mail-icon"><FaEnvelope /></span>
                                    <span className="elementor-icon-list-text">Need support? Email us! </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}


export default Footer;