import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import WelcomeSection from './components/WelcomeSection';
import HowItWorksSection from './components/HowItWorksSection';
import WhatYouGetSection from './components/WhatYouGetSection';
import OneStepCloserSection from './components/OneStepCloserSection';
import ImageSection from './components/ImageSection';
import ErrorModal from './components/ErrorModal';
import Loading from './components/Loading';
import BackendApi from './services/backend';

function App() {
	const [loading, setLoading] = useState(true);
	const [practiceid, setPracticeid] = useState("");
	const [practiceName, setPracticeName] = useState("");
	const [code, setCode] = useState("");
	const [buttonHref, setButtonHref] = useState("");

	const getParams = async () => {
		const params = new URLSearchParams(window.location.search); 
		const practiceId = params.get('practiceid'); 
		setPracticeid(practiceId);
		const code = params.get('code')
		setCode(code);
		setButtonHref(`${process.env.REACT_APP_JOTFORM_URL}?practiceid=${practiceId}&code=${code}`);

		if(practiceId && code) {
			const result = await BackendApi.getPracticeInfo(practiceId);
			setPracticeName(result.data.name);
		}

		setLoading(false);
	}

	useEffect(() => {	
		getParams();
	}, []);

	const loadingHtml = loading ?  <Loading /> : <></>

	return (
		<div className={`App ${loading ? 'App-loading' : ''}`}>
			{loadingHtml}
			<ErrorModal practiceid={practiceid} code={code}/>
			<Header href={buttonHref} />	
			<ImageSection href={buttonHref} practiceName={practiceName}/>
			<WelcomeSection />
			<HowItWorksSection href={buttonHref} />
			<WhatYouGetSection />
			<OneStepCloserSection href={buttonHref} />
			<Footer />			
		</div>
	);
}

export default App;

