import React from 'react';
import ReactLoading from 'react-loading';
import './index.css';

function Loading() {
    return (
        <div className="loading-container">
            <ReactLoading type="spin" color="#787878"/>
        </div>
    )
}

export default Loading;
