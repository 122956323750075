import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ErrorModal(props) { 
    return (
        <Modal
            show={props.practiceid == null || !props.code == null}
        >
            <Modal.Header>
                <Modal.Title>Error!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p> The following params are missing in the URL: </p>
                <ul>
                    { props.practiceid ? 
                        <></> : 
                        <li>
                            <p> practiceid</p>
                        </li>
                    }
                    { props.code ? 
                        <></> : 
                        <li>
                            <p> code </p>
                        </li>
                    }
                </ul>
            </Modal.Body>
        </Modal>
    )
}


export default ErrorModal;