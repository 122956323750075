import React from 'react';
import './index.css';
import Button from '../Button';
import MediaQuery from 'react-responsive'

function HowItWorksSection(props) {
    return (
        <section className="how-it-works-section">
            <div className="elementor-container">
                <div className="elementor-widget-wrap">
                    <div className="separator-wrapper elementor-element"> 
                        <hr className="separator"/> 
                    </div>
                    <div className="elementor-widget elementor-element">
                        <div className="title-wrapper-1">
                            <h1 className="elementor-heading-title title">HOW IT WORKS</h1>
                        </div>
                    </div>
                    <section className="elementor-element card-wrapper">
                        <div className="elementor-row">
                            <div className="elementor-column card-image">
                                <div className="elementor-widget-image">
                                    <img 
                                        decoding="async" 
                                        src="https://wellworld.io/wp-content/uploads/2022/10/take.survey-e1666313171705-1024x1024.jpg" 
                                        className="attachment-large size-large wp-image-6499" 
                                        alt="" 
                                        loading="lazy" 
                                        srcSet="https://wellworld.io/wp-content/uploads/2022/10/take.survey-e1666313171705-1024x1024.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/take.survey-e1666313171705-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/take.survey-e1666313171705-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/take.survey-e1666313171705-768x768.jpg 768w" 
                                        sizes="(max-width: 800px) 100vw, 800px"
                                        width="800" 
                                        height="800" />
                                </div>
                            </div>
                            <div className="elementor-column card-text">
                                <div className="card-text-color-1 elementor-widget-wrap">
                                    <div className="number-wrapper elementor-widget-heading">
                                        <h1 className="elementor-heading-title number">1</h1>
                                    </div>
                                    <div className="text-wrapper elementor-element">
                                        <h2 className="elementor-heading-title text">Take the health & wellness assessment survey</h2>          
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="elementor-element card-wrapper">
                        <div className="elementor-row">
                            <MediaQuery maxWidth={767}>                        
                                <div className="elementor-column card-image">
                                    <div className="elementor-widget-image">
                                        <img 
                                            decoding="async" 
                                            src="https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-1024x1024.jpg" 
                                            className="attachment-large size-large wp-image-6508" 
                                            alt="" 
                                            loading="lazy" 
                                            srcSet="https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-1024x1024.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-768x768.jpg 768w, https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium.jpg 1404w" 
                                            sizes="(max-width: 800px) 100vw, 800px" 
                                            width="800" 
                                            height="800" />
                                    </div>
                                </div>
                                <div className="elementor-column card-text">
                                        <div className="card-text-color-2 elementor-widget-wrap">
                                            <div className="number-wrapper elementor-widget-heading">
                                                <h1 className="elementor-heading-title number">2</h1>
                                            </div>
                                            <div className="text-wrapper elementor-element">
                                                <h2 className="elementor-heading-title text">Get your FREE personalized supplement recommendation</h2>          
                                            </div>
                                        </div>
                                </div>   
                            </MediaQuery>
                            <MediaQuery minWidth={768}>                        
                                <div className="elementor-column card-text">
                                        <div className="card-text-color-2 elementor-widget-wrap">
                                            <div className="number-wrapper elementor-widget-heading">
                                                <h1 className="elementor-heading-title number">2</h1>
                                            </div>
                                            <div className="text-wrapper elementor-element">
                                                <h2 className="elementor-heading-title text">Get your FREE personalized supplement recommendation</h2>          
                                            </div>
                                        </div>
                                </div>
                                <div className="elementor-column card-image">
                                    <div className="elementor-widget-image">
                                        <img 
                                            decoding="async" 
                                            src="https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-1024x1024.jpg" 
                                            className="attachment-large size-large wp-image-6508" 
                                            alt="" 
                                            loading="lazy" 
                                            srcSet="https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-1024x1024.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium-768x768.jpg 768w, https://wellworld.io/wp-content/uploads/2022/10/dude.phone_.stadium.jpg 1404w" 
                                            sizes="(max-width: 800px) 100vw, 800px" 
                                            width="800" 
                                            height="800" />
                                    </div>
                                </div>   
                            </MediaQuery>                            
                        </div>
                    </section>                 
                    <section className="elementor-element card-wrapper">
                        <div className="elementor-row">
                            <div className="elementor-column card-image">
                                <div className="elementor-widget-image">
                                    <img 
                                        decoding="async" 
                                        src="https://wellworld.io/wp-content/uploads/2022/10/iStock-1337895264-1024x1024.jpg" 
                                        className="attachment-large size-large wp-image-6513" 
                                        alt="" 
                                        loading="lazy" 
                                        srcSet="https://wellworld.io/wp-content/uploads/2022/10/iStock-1337895264-1024x1024.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/iStock-1337895264-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/iStock-1337895264-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/iStock-1337895264-768x768.jpg 768w" 
                                        sizes="(max-width: 800px) 100vw, 800px" 
                                        width="800" 
                                        height="800" />
                                </div>
                            </div>
                            <div className="elementor-column card-text">
                                <div className="card-text-color-3 elementor-widget-wrap">
                                    <div className="number-wrapper elementor-widget-heading">
                                        <h1 className="elementor-heading-title number">3</h1>
                                    </div>
                                    <div className="text-wrapper elementor-element">
                                        <h2 className="elementor-heading-title text">Order your recommended supplements</h2>          
                                    </div>
                                    <div className="subtext-wrapper elementor-element">
                                        <p>
                                            <em>You can even shop for additional products!</em>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="elementor-element card-wrapper">
                        <div className="elementor-row">
                            <MediaQuery maxWidth={767}>                        
                                <div className="elementor-column card-image">
                                    <div className="elementor-widget-image">
                                        <img 
                                            decoding="async" 
                                            src="https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-1024x1022.jpg" 
                                            className="attachment-large size-large wp-image-6517" 
                                            alt="" 
                                            loading="lazy" 
                                            srcSet="https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-1024x1022.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-768x766.jpg 768w" 
                                            sizes="(max-width: 800px) 100vw, 800px" 
                                            width="800" 
                                            height="798" />
                                    </div>
                                </div>       
                                <div className="elementor-column card-text">
                                        <div className="card-text-color-4 elementor-widget-wrap">
                                            <div className="number-wrapper elementor-widget-heading">
                                                <h1 className="elementor-heading-title number">4</h1>
                                            </div>
                                            <div className="text-wrapper elementor-element">
                                                <h2 className="elementor-heading-title text">Get your supplements delivered right to your doorstep.</h2>          
                                            </div>
                                        </div>
                                </div>   
                            </MediaQuery>
                            <MediaQuery minWidth={768}>
                                <div className="elementor-column card-text">
                                        <div className="card-text-color-4 elementor-widget-wrap">
                                            <div className="number-wrapper elementor-widget-heading">
                                                <h1 className="elementor-heading-title number">4</h1>
                                            </div>
                                            <div className="text-wrapper elementor-element">
                                                <h2 className="elementor-heading-title text">Get your supplements delivered right to your doorstep.</h2>          
                                            </div>
                                        </div>
                                </div>
                                <div className="elementor-column card-image">
                                    <div className="elementor-widget-image">
                                        <img 
                                            decoding="async" 
                                            src="https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-1024x1022.jpg" 
                                            className="attachment-large size-large wp-image-6517" 
                                            alt="" 
                                            loading="lazy" 
                                            srcSet="https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-1024x1022.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/package.welcome.mat_-768x766.jpg 768w" 
                                            sizes="(max-width: 800px) 100vw, 800px" 
                                            width="800" 
                                            height="798" />
                                    </div>
                                </div>  
                            </MediaQuery>                          
                        </div>
                    </section>
                    <div className="elementor-element">
                        <div className="button-container-1">
                            <Button href={props.href} title="TAKE THE HEALTH ASSESSMENT" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWorksSection;
