import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

const getPracticeInfo = async practiceId => instance.get(`/practice/${practiceId}/info`)

const BackendApi = {
    getPracticeInfo
}

export default BackendApi;