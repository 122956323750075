import React from 'react';
import './index.css';

function WelcomeSection(props) {
    return (
        <section className="welcome-section">
            <div className="elementor-container">
                <div className="elementor-widget-wrap">
                    <section>
                        <div className="elementor-widget-wrap">
                            <div className="title-wrapper-2 elementor-container">
                                <h1 className="title elementor-heading-title"> WELCOME TO A WHOLE NEW WORLD OF PERSONALIZED HEALTH & WELLNESS! </h1>
                            </div>
                        </div>
                    </section>
                    <section className="circle-section">
                        <div className="elementor-container">
                            <div className="elementor-row">
                                <div className="elementor-column elementor-col-33">
                                    <div className="elementor-column-wrap">
                                        <div className="elementor-image">
                                            <img 
                                                decoding="async" 
                                                src="https://wellworld.io/wp-content/uploads/2022/10/doc.nutrition.primalmulti.crop_-768x764.jpg" 
                                                className="circle-image" 
                                                alt="" 
                                                loading="lazy" 
                                                srcSet="https://wellworld.io/wp-content/uploads/2022/10/doc.nutrition.primalmulti.crop_-768x764.jpg 768w, https://wellworld.io/wp-content/uploads/2022/10/doc.nutrition.primalmulti.crop_-300x298.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/doc.nutrition.primalmulti.crop_-1024x1019.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/doc.nutrition.primalmulti.crop_-150x150.jpg 150w" 
                                                sizes="(max-width: 768px) 100vw, 768px" 
                                                width="768" 
                                                height="764" />
                                        </div>
                                        <div className="elementor-text">
                                            <p> Designed to address your specific needs and help you reach your wellness goals. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-33">
                                    <div className="elementor-column-wrap">
                                        <div className="elementor-image">
                                            <img 
                                                decoding="async" 
                                                src="https://wellworld.io/wp-content/uploads/2022/10/woman.framed.face_-768x768.jpg" 
                                                className="circle-image" 
                                                alt="" 
                                                loading="lazy" 
                                                srcSet="https://wellworld.io/wp-content/uploads/2022/10/woman.framed.face_-768x768.jpg 768w, https://wellworld.io/wp-content/uploads/2022/10/woman.framed.face_-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/woman.framed.face_-1024x1024.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/woman.framed.face_-150x150.jpg 150w" 
                                                sizes="(max-width: 768px) 100vw, 768px" 
                                                width="768" 
                                                height="768" />
                                            </div>
                                        <div className="elementor-text">
                                            <p> All based on your unique health & wellness profile. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-33">
                                    <div className="elementor-column-wrap">
                                        <div className="elementor-image">
                                            <img 
                                                decoding="async" 
                                                src="https://wellworld.io/wp-content/uploads/2022/10/pkg.doorstep.above_.crop_-768x764.jpg" 
                                                className="circle-image"
                                                alt="" 
                                                loading="lazy" 
                                                srcSet="https://wellworld.io/wp-content/uploads/2022/10/pkg.doorstep.above_.crop_-768x764.jpg 768w, https://wellworld.io/wp-content/uploads/2022/10/pkg.doorstep.above_.crop_-1024x1019.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/pkg.doorstep.above_.crop_-150x150.jpg 150w" 
                                                sizes="(max-width: 768px) 100vw, 768px" 
                                                width="768" 
                                                height="764" />
                                        </div>
                                        <div className="elementor-text">
                                            <p> Delivered right to your doorstep! </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>                
            </div>
        </section>
    )
}


export default WelcomeSection;