import React from 'react';
import './index.css';
import Button from '../Button';
import MediaQuery from 'react-responsive'

function ImageSection(props) {
    return (
        <>
            <MediaQuery maxWidth={767}> 
                <section>
                    <div className="elementor-widget-wrap">
                        <div className="elementor-image-1">
                            <img 
                                decoding="async" 
                                src="" 
                                className="attachment-large size-large wp-image-6762" 
                                alt="" 
                                loading="lazy" 
                                srcSet="https://wellworld.io/wp-content/uploads/2022/10/dfh.detoxkit.counter.crop_-1024x575.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/dfh.detoxkit.counter.crop_-300x169.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/dfh.detoxkit.counter.crop_-150x84.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/dfh.detoxkit.counter.crop_-768x431.jpg 768w, https://wellworld.io/wp-content/uploads/2022/10/dfh.detoxkit.counter.crop_.jpg 1474w" 
                                sizes="(max-width: 800px) 100vw, 800px" 
                                width="800" 
                                height="449" />
                        </div>
                    </div>
                </section>
                <section className="image-section">
                    <div className="elementor-container">
                        <div className="image-container-4 elementor-widget-wrap">
                            <div className="elementor-element elementor-widget">
                                <div className="title-wrapper-7">
                                    <h1 className="elementor-heading-title"> 
                                        Take the guesswork out of your health and wellness journey with {props.practiceName} and Designs for Health.
                                    </h1>
                                </div>
                            </div>
                            <div className="elementor-container">
                                <div className="text-container-2">
                                    <div className="text-wrapper-4">
                                        <p> Get a personalized supplement recommendation designed just for YOU! </p>
                                        <p> Your body, your challenges, your goals! </p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="button-container-3">
                                <Button href={props.href} title="TAKE THE HEALTH ASSESSMENT" />
                            </div>
                        </div>
                    </div>
                </section>                 
            </MediaQuery>
            <MediaQuery minWidth={768}> 
                <section className="image-section">
                    <div className="elementor-container">
                        <div className="image-container-4 elementor-widget-wrap">
                            <div className="elementor-element elementor-widget">
                                <div className="title-wrapper-7">
                                    <h1 className="elementor-heading-title"> 
                                        Take the guesswork out of your health and wellness journey with {props.practiceName} and Designs for Health.
                                    </h1>
                                </div>
                            </div>
                            <div className="elementor-element elementor-widget">
                                <div className="blank-1">                            
                                </div>
                            </div>
                            <section className="elementor-element text-section">
                                <div className="elementor-container">
                                    <div className="blank-2">                                
                                    </div>
                                    <div className="text-container-2">
                                        <div className="text-wrapper-4">
                                            <p> Get a personalized supplement recommendation designed just for YOU! </p>
                                            <p> Your body, your challenges, your goals! </p>
                                        </div>
                                        <div className="button-container-3">
                                            <Button href={props.href} title="TAKE THE HEALTH ASSESSMENT" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section> 
            </MediaQuery>
        </>        
    )
}


export default ImageSection;