import React from 'react';
import './index.css';
import MediaQuery from 'react-responsive'

function WhatYouGetSection() {
    return (
        <section className="what-you-get-section"> 
            <div className="elementor-container">
                <div className="elementor-widget-wrap">
                    <div className="elementor-widget elementor-element">
                        <div className="title-wrapper-3">
                            <h1 className="elementor-heading-title">WHAT YOU GET</h1>
                        </div>
                    </div>
                    <section className="elementor-element">
                        <div className="elementor-container">
                            <div className="card-container-1">
                                <div className="card-1 elementor-column-wrap">
                                    <div className="card-content">
                                        <div className="elementor-widget">
                                            <div className="title-wrapper-4">
                                                <h3 className="elementor-heading-title title card-title">YOUR UNIQUE RECOMMENDATION</h3>
                                            </div>
                                        </div>
                                        <div className="text-wrapper-2">
                                            <ul>
                                                <li>
                                                    <p> Taking into account your age, your lifestyle, conditions, and goals. </p>
                                                </li>
                                                <li>
                                                    <p> Recommended dosages and schedule </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="image-container-1">
                                <div className="elementor-column-wrap">
                                    <div className="image-wrapper-1">
                                        <img 
                                            decoding="async" 
                                            src="https://wellworld.io/wp-content/uploads/2022/11/ww.wellness.survey.success-1024x1024.jpg" 
                                            className="image" 
                                            alt="" 
                                            loading="lazy" 
                                            srcSet="https://wellworld.io/wp-content/uploads/2022/11/ww.wellness.survey.success-1024x1024.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/11/ww.wellness.survey.success-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/11/ww.wellness.survey.success-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/11/ww.wellness.survey.success-768x768.jpg 768w" 
                                            sizes="(max-width: 800px) 100vw, 800px" 
                                            width="800" 
                                            height="800" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="elementor-element">                        
                        <div className="elementor-container">
                            <MediaQuery maxWidth={767}>                                 
                                <div className="card-container-2">
                                    <div className="card-2 elementor-column-wrap">
                                        <div className="card-content">
                                            <div className="elementor-widget">
                                                <div className="title-wrapper-4">
                                                    <h3 className="elementor-heading-title title card-title">YOUR PERSONALIZED SUPPLEMENTS</h3>
                                                </div>
                                            </div>
                                            <div className="text-wrapper-2">
                                                <ul>
                                                    <li>
                                                        <p> Designed by DFH health experts </p>
                                                    </li>
                                                    <li>
                                                        <p> Proprietary products </p>
                                                    </li>
                                                    <li>
                                                        <p> Highest quality ingredients </p>
                                                    </li>
                                                    <li>
                                                        <p> Commitment to sustainability </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="image-container-2">
                                    <div className="elementor-column-wrap">
                                        <div className="image-wrapper-2">
                                            <img 
                                                decoding="async" 
                                                src="https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-1024x1024.jpg" 
                                                className="image" 
                                                alt="" 
                                                loading="lazy" 
                                                srcSet="https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-1024x1024.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-768x768.jpg 768w" 
                                                sizes="(max-width: 800px) 100vw, 800px" 
                                                width="800" 
                                                height="800" />
                                        </div>
                                    </div>
                                </div>
                            </MediaQuery>
                            <MediaQuery minWidth={768}> 
                                <div className="image-container-2">
                                    <div className="elementor-column-wrap">
                                        <div className="image-wrapper-2">
                                            <img 
                                                decoding="async" 
                                                src="https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-1024x1024.jpg" 
                                                className="image" 
                                                alt="" 
                                                loading="lazy" 
                                                srcSet="https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-1024x1024.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/iStock-1266118645-e1666330111886-768x768.jpg 768w" 
                                                sizes="(max-width: 800px) 100vw, 800px" 
                                                width="800" 
                                                height="800" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container-2">
                                    <div className="card-2 elementor-column-wrap">
                                        <div className="card-content">
                                            <div className="elementor-widget">
                                                <div className="title-wrapper-4">
                                                    <h3 className="elementor-heading-title title card-title">YOUR PERSONALIZED SUPPLEMENTS</h3>
                                                </div>
                                            </div>
                                            <div className="text-wrapper-2">
                                                <ul>
                                                    <li>
                                                        <p> Designed by DFH health experts </p>
                                                    </li>
                                                    <li>
                                                        <p> Proprietary products </p>
                                                    </li>
                                                    <li>
                                                        <p> Highest quality ingredients </p>
                                                    </li>
                                                    <li>
                                                        <p> Commitment to sustainability </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            </MediaQuery>                                                    
                        </div>
                    </section>
                    <section className="elementor-element">
                        <div className="elementor-container">
                            <div className="card-container-1">
                                <div className="card-1 elementor-column-wrap">
                                    <div className="card-content">
                                        <div className="elementor-widget">
                                            <div className="title-wrapper-4">
                                                <h3 className="elementor-heading-title title card-title">DELIVERED TO YOUR DOORSTEP!</h3>
                                            </div>
                                        </div>
                                        <div className="text-wrapper-2">
                                            <ul>
                                                <li>
                                                    <p> Quick shipping and delivery of purchases supplements </p>
                                                </li>
                                                <li> 
                                                    <p> Support for all your product questions </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="image-container-1">
                                <div className="elementor-column-wrap">
                                    <div className="image-wrapper-1">
                                        <img 
                                            decoding="async" 
                                            src="https://wellworld.io/wp-content/uploads/2022/10/woman.opening.package-1024x1024.jpg" 
                                            className="image" 
                                            alt="" 
                                            loading="lazy" 
                                            srcSet="https://wellworld.io/wp-content/uploads/2022/10/woman.opening.package-1024x1024.jpg 1024w, https://wellworld.io/wp-content/uploads/2022/10/woman.opening.package-300x300.jpg 300w, https://wellworld.io/wp-content/uploads/2022/10/woman.opening.package-150x150.jpg 150w, https://wellworld.io/wp-content/uploads/2022/10/woman.opening.package-768x768.jpg 768w" 
                                            sizes="(max-width: 800px) 100vw, 800px" 
                                            width="800" 
                                            height="800" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>  
        </section>

    )
}

export default WhatYouGetSection;
