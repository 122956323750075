import React from 'react';
import './index.css';

function Button(props) {
    return (
        <div className="button-wrapper">
            <div className="button">
                <a href={props.href} className="" role="button">
                    <span className="">
                        {props.title}                    
                    </span>
                </a>
            </div>
        </div>
    )
}


export default Button;